/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, PriceWrap } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader />

        <Column className="pb--40 pt--80" name={"cennik"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":700}} content={"Cennik"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"cennik-2"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Zacznij pisać, to pole jest przeznaczone na twój dowolny tekst."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Zacznij pisać, to pole jest przeznaczone na twój dowolny tekst."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Zacznij pisać, to pole jest przeznaczone na twój dowolny tekst."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Zacznij pisać, to pole jest przeznaczone na twój dowolny tekst."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Zacznij pisać, to pole jest przeznaczone na twój dowolny tekst."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--30" name={"kontakt"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":700}} content={"Pańska 18, Warszawa<br>+48 797 811 2X0<br>info@twoje-stronyy.com"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":""}} content={"<a href=\"https://saywebpage.com\">Tworzenie stron</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}